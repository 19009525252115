var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"points-item"},[_c('div',{staticClass:"left"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"right"},[((_vm.typeProxy)==='checkbox')?_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:"w-60p  block px-4 py-1.5 leading-normal bg-gray-200 text-gray-800 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
        'point text-oLightGray placeholder-oLightGray': _vm.getDisableStatus,
        'has-value': _vm.hasValue,
      },attrs:{"disabled":_vm.getDisableStatus,"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.valueProxy)?_vm._i(_vm.valueProxy,null)>-1:(_vm.valueProxy)},on:{"change":function($event){var $$a=_vm.valueProxy,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.valueProxy=$$a.concat([$$v]))}else{$$i>-1&&(_vm.valueProxy=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.valueProxy=$$c}}}},_vm.getListeners)):((_vm.typeProxy)==='radio')?_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:"w-60p  block px-4 py-1.5 leading-normal bg-gray-200 text-gray-800 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
        'point text-oLightGray placeholder-oLightGray': _vm.getDisableStatus,
        'has-value': _vm.hasValue,
      },attrs:{"disabled":_vm.getDisableStatus,"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.valueProxy,null)},on:{"change":function($event){_vm.valueProxy=null}}},_vm.getListeners)):_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.valueProxy),expression:"valueProxy"}],ref:"inputRef",staticClass:"w-60p  block px-4 py-1.5 leading-normal bg-gray-200 text-gray-800 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
        'point text-oLightGray placeholder-oLightGray': _vm.getDisableStatus,
        'has-value': _vm.hasValue,
      },attrs:{"disabled":_vm.getDisableStatus,"placeholder":_vm.placeholder,"type":_vm.typeProxy},domProps:{"value":(_vm.valueProxy)},on:{"input":function($event){if($event.target.composing){ return; }_vm.valueProxy=$event.target.value}}},_vm.getListeners)),_c('div',{staticClass:"w-40p point-edit"},[_c('div',[(_vm.getEditStatus)?_c('div',{staticClass:" flex pl-2"},[_c('anchor-button',{attrs:{"onlyShowIcon":true},on:{"click":_vm.handleCancel},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"fas fa-ban  text-oWhite"})]},proxy:true}],null,false,488599655)}),_c('anchor-button',{staticClass:"ml-2",attrs:{"onlyShowIcon":true,"variant":"success"},on:{"click":_vm.handleSave},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"fas fa-check  text-oWhite"})]},proxy:true}],null,false,4153634092)})],1):_vm._e()]),_c('div',{staticClass:"pencil-icon "},[(!_vm.getEditStatus)?_c('div',[_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"},on:{"click":function($event){return _vm.handleEdit(_vm.id)}}},[_c('path',{attrs:{"strokeLinecap":"round","strokeLinejoin":"round","strokeWidth":"{2}","d":"M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"}})])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }