<template lang="">
  <div class="points-item">
    <div class="left">{{ title }}</div>
    <div class="right">
      <input
        ref="inputRef"
        class="w-60p  block px-4 py-1.5 leading-normal bg-gray-200 text-gray-800 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
        :class="{
          'point text-oLightGray placeholder-oLightGray': getDisableStatus,
          'has-value': hasValue,
        }"
        v-on="getListeners"
        :type="typeProxy"
        :disabled="getDisableStatus"
        :placeholder="placeholder"
        v-model="valueProxy"
      />
      <div class="w-40p point-edit">
        <div>
          <div v-if="getEditStatus" class=" flex pl-2">
            <anchor-button @click="handleCancel" :onlyShowIcon="true">
              <template #icon>
                <i class="fas fa-ban  text-oWhite"></i
              ></template>
            </anchor-button>
            <anchor-button
              @click="handleSave"
              :onlyShowIcon="true"
              variant="success"
              class="ml-2"
            >
              <template #icon>
                <i class="fas fa-check  text-oWhite"></i
              ></template>
            </anchor-button>
          </div>
        </div>
        <div class="pencil-icon ">
          <div v-if="!getEditStatus">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              @click="handleEdit(id)"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="{2}"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from '@/utils'
import AnchorButton from '@/components/form/AnchorButton'
export default {
  name: 'SmartPointInput',
  components: { AnchorButton },
  props: {
    rowId: {
      type: [String, Number],
      default: 'PointInputId',
    },
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    point: {
      type: null,
      default: '',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeProxy: '',
      valueProxy: '',
      editableStatus: false,
      previousData: null,
      group: null,
      id: null,
    }
  },
  created() {
    this.previousData = this.valueProxy
  },
  computed: {
    hasValue() {
      return !!this.valueProxy
    },
    getDisableStatus() {
      if (this.editableStatus) {
        return false
      } else {
        return true
      }
    },
    getEditStatus() {
      if (this.editableStatus) {
        return true
      } else {
        return false
      }
    },
    getListeners() {
      return Object.assign({}, this.$listeners, {
        // handle custom event as well as a fallback to the native events
        input: (event) => {
          const e = event.target.value
          this.$emit('input', e)
        },
      })
    },
  },
  watch: {
    rowId: {
      deep: false,
      immediate: true,
      handler(val) {
        if (val) {
          this.group = val.split('_')[0]
          this.id = val.split('_')[1]
        }
      },
    },
    point: {
      deep: false,
      immediate: true,
      handler(val) {
        if (val !== this.valueProxy) {
          this.valueProxy = val
        }
      },
    },

    type: {
      deep: false,
      immediate: true,
      handler(val) {
        if (val !== this.typeProxy) {
          this.typeProxy = val
        }
      },
    },
    isEdit: {
      deep: false,
      immediate: true,
      handler(status) {
        if (status) {
          this.editableStatus = true
        } else {
          this.editableStatus = false
          if (this.previousData !== null) {
            this.valueProxy = this.previousData
          }
        }
      },
    },
  },
  methods: {
    handleCancel() {
      this.editableStatus = !this.editableStatus
      if (this.previousData !== null) {
        this.valueProxy = this.previousData
      }
    },
    handleSave() {
      this.editableStatus = !this.editableStatus
      this.$emit('save', { title: this.title, value: this.valueProxy })
    },
    handleEdit(id) {
      EventBus.$emit('open-edit', this.group, id)
      this.editableStatus = !this.editableStatus
    },
    focus() {
      this.$refs.inputRef.focus()
    },
  },
}
</script>
<style lang="scss" scoped>
$border: 1px solid #d5d5d5;
.points-item {
  display: flex;
  align-items: center;
  height: 42px;
  border-left: $border;
  border-right: $border;
  border-bottom: $border;
  font-size: 14px;
  color: #484848;
  font-weight: 400;
  .left {
    width: 70%;
    padding: 8px;
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    padding: 8px;
    border-left: $border;

    .point-edit {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.pencil-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.w-40p {
  width: 40%;
}
.w-60p {
  width: 60%;
}
.point {
  background: white !important;
}
</style>
